/*******************************************************************************
 * Landing.js
 * 
 * Main landing javascript application definition - a thin wrapper around the
 * other components that are used for the 4-H Photo Uploader.
 ******************************************************************************/
import React from "react";
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import LandingOptionGroup from "./components/LandingOptions";
import { withRouter } from "../router-support";
import UploadApp from './UploadApp';

function EventLanding(props) {
  const [events, setEvents] = React.useState([]);
  const [dataLoadComplete, setDataLoadComplete] = React.useState(false);
  const [searchParams,] = useSearchParams();
  
  React.useEffect(()=>{
    
    var requestPath = `/api/v1/selection/${props.selection}`;

    console.log(props.params.region);

    if (!!props.params.region) {
      requestPath += `/${props.params.region}`;
    }

    // Load the Events from Which Selection Can be Made
    fetch(requestPath, {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      setEvents(jsonData);
      setDataLoadComplete(true);
    }).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
    
  },[props.params.region, props.selection]);

  return (
    <>
      {!dataLoadComplete && <CircularProgress />}
      {!searchParams.get("county_wide")
        // Display the Navigation Options
        ? <>
          {!!props.useGeneric &&
            <LandingOptionGroup
              eventsList={[
                {
                  name: "",
                  slug: `All ${props.params.region} County`,
                  path: "?county_wide=True",
                  image_id: ""
                },
              ]}
            />
          }
          <LandingOptionGroup
            eventsList={events}
            dataLoadComplete={dataLoadComplete}
          />
        </>
        // Present the Upload App for the County-Wide Option
        : <UploadApp />
      }
    </>
  );
}

export default withRouter(EventLanding);
