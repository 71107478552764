/*******************************************************************************
 * SettingsTable - A generic table to allow settings objects to add/edit/remove
 * content from a table for the 4-H Photo Uploader.
 ******************************************************************************/

import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { EditableRow, EditableLine, StyledTableCell } from './SettingRow';

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function BestBetterDataTable({
  rows, // callback to show rows
  onChange, // callback to change values
  tableName = "Name"
}) {
  
  const modifyRows = (keyingValue, newValueTuple) => {
    let newRowList = rows;
    // Single Item Data Row
    var index = rows.indexOf(keyingValue);
    if (index >= 0) {
      newRowList[index] = newValueTuple; // apply update
    }
    
    onChange(newRowList);
  }

  const removeRow = (keyingValue) => {
    // Event Details Only Uses Key of First Item
    let newRowList = [];
    // Single Item Data Row
    var index = rows.indexOf(keyingValue);
    if (index >= 0) {
      // Found Entry
      delete rows[index]; // remove the row
      for (let entry of rows) {
        // verify that each value is actually a string -- ignore the null
        if (typeof entry === 'string' || entry instanceof String) {
          newRowList.push(entry);
        }
      }
    }
    onChange(newRowList);
  }

  const addRow = () => {
    var newRowList = rows.concat("New");
    onChange(newRowList);
  }

   return (
    <Container maxWidth={false}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{tableName}</StyledTableCell>
              <StyledTableCell align="right">Edit</StyledTableCell>
              <StyledTableCell align="right">Delete</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((valueTuple) => (
              <EditableRow
                values={valueTuple}
                onChange={modifyRows}
                onRemove={removeRow}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <Button variant="contained" onClick={addRow}>Add</Button>
     </Container>
   );
}
 

export function BestBetterSettingsTable({
   rows, // callback to show rows
   onChange, // callback to change values
   tableName, // name to display in the table header
}) {
  
  const modifyRows = (keyingValue, newValueTuple) => {
    rows[keyingValue].value = newValueTuple;
    onChange(rows);
  }

  return (
    <Container maxWidth={false}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell colSpan={3}>{tableName}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(rows).map(([setting_key, settings]) => (
              <EditableLine
                rowKey={setting_key}
                dataName={settings.name}
                value={settings.value}
                treatAsSecret={settings.secret}
                onChange={modifyRows}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export function BestBetterTabTable({
  tabs, // listed strings to show as the visible tabs
  rows, // callback to show rows
  onChange, // callback to change values
  tableName = "Name"
}) {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateData = (newData, keyingValue) => {
    let newRows = rows;
    newRows[keyingValue] = newData;
    onChange(newRows);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleTabChange} aria-label="best-better-tabs">
          {tabs.map((district, index) => (
            <Tab label={district} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((district, index) => (
        <TabPanel value={value} index={index}>
          {(district in rows) &&
            <BestBetterDataTable
              rows={rows.district}
              onChange={(data) => {updateData(data, district)}}
              tableName={tableName}
            />
          }
        </TabPanel>
      ))}
    </Box>
  )
}
