/*******************************************************************************
 * AppBar.js
 * 
 * Application bar (header-bar) to provide selection options for the various
 * "things" that should be accessible in the application.
 ******************************************************************************/
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import FloatingInfoButton from './components/FloatingInfoButton';
import PageQrCode from './components/PageQrCode';

export default function BestBetterAppBar({
  mode,
  onThemeChange, // called every time the user clicks the LightDarkSwitch
}) {
  const [qrOpen, setQrOpen] = React.useState(false);
  const [albumId, setAlbumId] = React.useState(null);
  const [pageTitle, setPageTitle] = React.useState(document.title);

  // Function to help secure usage of `_blank`
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const openAlbums = () => {
    openInNewTab(window.application_info.albums_redirect);
    handleMenuClose();
  };
  const openThisAlbum = () => {
    let linkPath = window.application_info.albums_redirect;
    if (!!albumId) {
      linkPath += "/gallery#" + albumId;
    }
    openInNewTab(linkPath);
    handleMenuClose();
  }
  const openAdmin = () => {
    window.location.href = "/admin";
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  React.useEffect(()=>{

    // Evaluate the most relevant page title
    if (window.application_info.event_title !== "None") {
      setPageTitle(window.application_info.event_title);
      
      // Get Album Information
      fetch(`/api/v1/album-id?event_path=${encodeURI(window.location.pathname)}`, {
        method: "GET",
        headers: {'Accept': 'application/json'},
      }).then(res => res.json()).then(jsonData => {
        // Set the Page Information
        setAlbumId(jsonData);
      }).catch((error) => {
        if( error.response ){
            console.log(error.response.data); // => the response payload
        }
      });
    }
    
  },[]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <PageQrCode open={qrOpen} onClickClose={() => {setQrOpen(false)}}/>
      <FloatingInfoButton />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            id="menu-button"
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={openAlbums}>View Albums</MenuItem>
            {window.application_info.event_title !== "None" &&
              <MenuItem onClick={openThisAlbum}>Explore This Album</MenuItem>
            }
            <MenuItem onClick={openAdmin}>Settings</MenuItem>
          </Menu>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link href="/" underline="none" color="inherit">
              {pageTitle}
            </Link>
          </Typography>
          <div>
            {!!albumId &&
              <Tooltip title="Get QR code for this page.">
                <IconButton
                  sx={{ ml: 1 }}
                  onClick={() => {setQrOpen(true)}}
                  color="inherit"
                >
                  <QrCode2Icon />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title={mode === 'dark' ? "Light Mode" : "Dark Mode"}>
              <IconButton sx={{ ml: 1 }} onClick={onThemeChange} color="inherit">
                {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
