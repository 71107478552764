/*******************************************************************************
 * CountyEventNames.js - View for County Event Names Page of Admin Interface.
 ******************************************************************************/

import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { api_client } from '../api';
import { fetchToken } from '../auth';
import { BestBetterEventTable } from "./fields/EventTable";

export default function CountyEventsView() {
  const [counties, setCounties] = React.useState([]);
  const [selectedCountyEvents, setSelectedCountyEvents] = React.useState([]);
  const [selectedCounty, setSelectedCounty] = React.useState('');

  React.useEffect(()=>{
    // Load Requisites when page Completes
    getCountyNames();
  },[]);

  const doUpdateCountyEvent = (event_data) => {
    // Be sure to clone the original object to trigger React's rerendering
    let events = structuredClone(selectedCountyEvents);
    // Iterate Events to Identify the Specific One by its ID
    Array.from(events).forEach((event, idx, _) => {
      if (event.event_id === event_data.event_id) {
        // Update the Specific Event
        events[idx] = event_data;
      }
    })
    setSelectedCountyEvents(events);
  }

  const getCountyNames = () => {
    api_client.get("counties/list", {
      headers: {'Accept': 'application/json'},
    }).then(res => res.data).then(jsonData => {
      // Set the County Names
      setCounties(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const handleCountyChange = (event) => {
    // Change the County Selection for Events
    setSelectedCounty(event.target.value);
    // Load the Selected County's Events
    api_client.get(`events/county/${event.target.value}/`, {
      headers: {'Accept': 'application/json'},
    }).then(res => res.data).then(jsonData => {
      // Set the State Event Names
      setSelectedCountyEvents(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const getCountyEventNames = (county) => {
    api_client.get(`events/county/${county}/`,
      {
        headers: {'Accept': 'application/json'},
      }
    ).then(res => res.data).then(jsonData => {
      // Set the State Event Names
      setSelectedCountyEvents(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const addCountyEvent = () => {
    api_client.put(`events/county/${selectedCounty}/`,
      null,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getCountyEventNames(selectedCounty)}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const removeCountyEvent = (event_id) => {
    // Get the specific event data
    const payload = selectedCountyEvents.filter(event => event.event_id === event_id)[0];
    api_client.delete("events/county/",
      {
        data: payload,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getCountyEventNames(selectedCounty)}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const storeCountyEvent = (event_id) => {
    // Get the specific event data
    const payload = selectedCountyEvents.filter(event => event.event_id === event_id)[0];
    api_client.post("events/county/",
      payload,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
      ).then(_ => {getCountyEventNames(selectedCounty)}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

 return (
  <>
    <FormControl fullWidth>
      <InputLabel id="county-selector">County</InputLabel>
      <Select
        labelId="county-selector-label"
        id="county-selector-select"
        value={selectedCounty}
        label="County"
        onChange={handleCountyChange}
      >
        {counties.map((county, _) => (
          <MenuItem value={county}>{county}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <br/>
    <br/>
    <BestBetterEventTable
      data={selectedCountyEvents}
      onAdd={addCountyEvent}
      onRemove={removeCountyEvent}
      onChange={doUpdateCountyEvent}
      onStore={storeCountyEvent}
      noDistrictComp={true}
    />
  </>
 )
}