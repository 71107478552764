/*******************************************************************************
 * DistrictNames.js - View for District Names Page of Admin Interface.
 ******************************************************************************/

import * as React from 'react';
import { api_client } from '../api';
import { fetchToken } from '../auth';
import { RegionTable } from "./fields/RegionTable";

export default function DistrictNamesView() {
  const [districtData, setDistrictData] = React.useState([]);

  React.useEffect(()=>{
    // Load Requisites when page Completes
    getDistrictData();
  },[]);

  const doUpdateDistrictData = (district_data) => {
    // Be sure to clone the original object to trigger React's rerendering
    let districts = structuredClone(districtData);
    // Iterate Districts to Identify the Specific One by its ID
    Array.from(districts).forEach((district, idx, _) => {
      if (district.id === district_data.id) {
        // Update the Specific District
        districts[idx] = district_data;
      }
    })
    setDistrictData(districts);
  }

  const getDistrictData = () => {
    api_client.get("districts/", {
      headers: {'Accept': 'application/json'},
    }).then(res => res.data).then(jsonData => {
      // Set the District District Names
      setDistrictData(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const addDistrict = () => {
    api_client.put("districts/",
      null,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getDistrictData()}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const removeDistrict = (id) => {
    // Get the specific district data
    const payload = districtData.filter(district => district.id === id)[0];
    api_client.delete("districts/",
      {
        data: payload,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getDistrictData()}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const storeDistrict = (id) => {
    // Get the specific district data
    const payload = districtData.filter(district => district.id === id)[0];
    api_client.post("districts/",
      payload,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
      ).then(_ => {getDistrictData()}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

 return (
    <RegionTable
      data={districtData}
      onAdd={addDistrict}
      onChange={doUpdateDistrictData}
      onRemove={removeDistrict}
      onStore={storeDistrict}
      tableName="District Name"
    />
 )
}