/*******************************************************************************
 * PhotoUploadForm - The General Upload Form for the Photo Tool.
 ******************************************************************************/

import React, { Component } from 'react';
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  FormLabel,
  FormControl,
  FormControlLabel,
  Snackbar,
  Radio,
  RadioGroup,
  Link,
  Typography,
  LinearProgress,
  Breadcrumbs
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MuiAlert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { DropzoneArea } from 'react-mui-dropzone';
import uploadEventPhotos from './api/upload';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export default class PhotoUploadForm extends Component {
  constructor(props){
    super(props);

    this.state = {
      files: [],
      district: '',
      competitionType: null,
      snackbarOpen: false,
      snackbarMsg: "",
      severity: "success",
      dropzoneKey: 0,
      uploading: false,
      progress: null,
      uploadPercentStepSize: null,
    };

    // Bind Class Methods
    this.handleDistrictChange = this.handleDistrictChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.uploadPhotos = this.uploadPhotos.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePopup = this.handlePopup.bind(this);
    this.handleRadioChanges = this.handleRadioChanges.bind(this);
    this.handleSuccessfulUpload = this.handleSuccessfulUpload.bind(this);
    this.handleBreadcrumbClick = this.handleBreadcrumbClick.bind(this);
  }

  handleDistrictChange = (event) => {
    this.setState({district: event.target.value});
  };

  handleRadioChanges = (event) => {
    this.setState({competitionType: event.target.value})
  }

  handleFileChange(files) {
    this.setState({
      files: files
    });
  }

  handleBreadcrumbClick(event) {
    event.preventDefault();
    window.location.href = event.target.href;
  }

  handleSwitchChange = (event) => {
    if (!!this.state.competitionType) {
      this.setState({competitionType: null})
    } else {
      this.setState({competitionType: 'district-competition'})
    }
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackbarOpen: false,
      snackbarMsg: "",
      uploading: false,
    });
  };

  handlePopup = (data) => {
    // Show Popup with Defined Message and Severity
    console.log(data.message);

    // Update Snackbar
    this.setState({
      uploading: false,
      snackbarMsg: data.message,
      snackbarOpen: true,
      severity: data.severity,
    })
  };

  handleSuccessfulUpload = (filename) => {
    if (!!filename) {
      // Remove the Image That was Just Uploaded
      this.setState({
        files: this.state.files.filter(
          function(f){return f.name !== filename;}
        ),
        dropzoneKey: this.state.dropzoneKey + 1,
        progress: this.state.progress + this.state.uploadPercentStepSize,
      });
    } else {
      // Turn Off Spinner, Reset Files List
      this.setState({
        uploading: false,
        files: [],
        dropzoneKey: 0,
        progress: null, // disable progress bar
      });
    }

    // Update Snackbar
    if (this.state.files.length === 0) {
      this.setState({
        snackbarMsg: `Successfully uploaded images.`,
        snackbarOpen: true,
        severity: "success",
      })

      // Turn Off Spinner, Reset Files List
      this.setState({
        uploading: false,
        files: [],
        dropzoneKey: this.state.dropzoneKey + 1,
        progress: null, // disable progress bar
      });
    }
  };

  // Photo Uploader Action
  uploadPhotos(event) {
    event.preventDefault();
    // Validate Photo List
    if (this.state.files.length === 0) {
      this.setState({
        snackbarMsg: "Select one or more photos, first!",
        snackbarOpen: true,
        severity: "warning"
      })
      return // Don't attempt the upload
    }
    // Validate Competition Configuration
    if (!!this.state.competitionType && this.state.district === '') {
      this.setState({
        snackbarMsg: "Select a district, first!",
        snackbarOpen: true,
        severity: "warning"
      })
      return // Don't attempt the upload
    }
    // Indicate Photos Are Being Submitted
    this.setState({
      uploading: true,
      progress: 1, // start with non-zero value to inform the user we're working
      uploadPercentStepSize: 100 / this.state.files.length,
    })

    // Determine Region Selection
    var pageUrl = new URL(window.location.href);

    // Perform Upload
    uploadEventPhotos({
      files: this.state.files,
      regionEventSelection: pageUrl.pathname,
      uploadOptions: {
        district_name: this.state.district,
        competition_type: this.state.competitionType,
      },
      onPopup: this.handlePopup,
      onSuccess: this.handleSuccessfulUpload
    });
  }

  render(){
    const pathname = window.location.pathname.split("/");
    return (
      <Box sx={{
        minWidth: 200,
        maxWidth: 1400,
      }}>
        <div>
        <div role="presentation" onClick={this.handleBreadcrumbClick}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              {this.props.title}
            </Link>
            {pathname.slice(1, -1).map((path, idx) => (
              <Link underline="hover" color="inherit" href={
                pathname.slice(0, pathname.indexOf(path) + 1).join("/")
              }>
                {idx === 0 &&
                  toTitleCase(decodeURI(path))
                }
                {idx > 0 &&
                  decodeURI(path)
                }
              </Link>
            ))}
            <Typography color="text.primary">
              {decodeURI(pathname.slice(-1))}
            </Typography>
          </Breadcrumbs>
        </div>
          {(window.application_info.district_competition_option.toLowerCase() === "true") &&
            <>
            <FormControlLabel fullWidth
            control={<Switch
              checked={!!this.state.competitionType}
              onChange={this.handleSwitchChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />}
            label="Upload as Part of District Competition"
            />
            {!!this.state.competitionType && <Box>
            <FormControl fullWidth>
              <InputLabel id="district-select-label">District</InputLabel>
              <Select
                labelId="district-select"
                id="district-select"
                value={this.state.district}
                label="District"
                onChange={this.handleDistrictChange}
              >
                {this.props.districts.map((district, _) => (
                  <MenuItem value={district}>{district}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br/>
            <br/>
            <FormLabel id="event-participation-type-label">Competition Type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="event-participation-type"
              name="event-participation-type"
              value={this.state.competitionType}
              onChange={this.handleRadioChanges}
            >
              <FormControlLabel value="district-competition" control={<Radio />} label="Best Photo Contest" />
              <FormControlLabel value="scavenger-hunt" control={<Radio />} label="Scavenger Hunt" />
            </RadioGroup>
            </Box>}
            </>
          }
          <FormLabel component="legend">&nbsp;</FormLabel>
          <FormControl fullWidth my={3}>
            <DropzoneArea
              key={this.state.dropzoneKey}
              clearOnUnmount="true"
              acceptedFiles={['image/*', 'video/*']}
              dropzoneText={"Drag or click to select images"}
              onChange={this.handleFileChange}
              filesLimit={50}
              maxFileSize={100_000_000}
              fileObjects={this.state.files}
              showPreviewsInDropzone={true}
              useChipsForPreview={true}
              showAlerts={['error', 'info']}
            />
          </FormControl>
          <FormControl id="PhotoUploadSubmit" fullWidth mt={3}>
            <LoadingButton
              onClick={this.uploadPhotos}
              endIcon={<SendIcon />}
              loading={this.state.uploading}
              loadingPosition="end"
              variant="contained"
            >
              Submit Photos
            </LoadingButton>
          </FormControl>
        </div>
        {!!this.state.progress &&
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={this.state.progress} />
        </Box>
        }
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert
            onClose={this.handleClose}
            severity={this.state.severity}
            sx={{ width: '100%' }}
          >
            {this.state.snackbarMsg}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

