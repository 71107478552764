/*******************************************************************************
 * RegionTable.js - A generic table row, allowing editing or deletion of data.
*******************************************************************************/

import * as React from 'react';
import {
    Button,
    Container,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { StyledTableCell, StyledTableRow } from './SettingRow';
import { EditableTextField, EditableOverflowTextField } from './EditableField';



/* EditiableRow is for n-row tables whose rows represent names. */
function RegionRow({
    regionObject, // JSON object describing the district
    onChange, // callback to modify the district data
    onStore, // callback to push modified changes to server
    onRemove, // callback to delete the indicated item from the server
}) {
    const [editActive, setEditActive] = React.useState(false);

    const doChange = (key, value) => {
      let data = regionObject;
      data[key] = value;
      onChange(data);
    }
  
    const toggleEdit = () => {
      setEditActive(!editActive);
      if (editActive) {
        onStore(regionObject.id);
      }
    }

    return (
        <StyledTableRow
        key={regionObject.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        <StyledTableCell component="tr" scope="row">
            <EditableTextField
            value={regionObject.name}
            inEditMode={editActive}
            setValue={(value) => {doChange("name", value)}}
            />
        </StyledTableCell>
        <StyledTableCell component="tr" scope="row" align="left">
            <EditableOverflowTextField
            value={regionObject.image_id}
            inEditMode={editActive}
            setValue={(value) => {doChange("image_id", value)}}
            />
        </StyledTableCell>
        <StyledTableCell align="right">
            <Button
              variant="outlined"
              color="secondary"
              onClick={toggleEdit}
            >
              {editActive? "Save" : "Edit"}
            </Button>
        </StyledTableCell>
        <StyledTableCell align="right">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {onRemove(regionObject.id)}}
            >
              Delete
            </Button>
        </StyledTableCell>
        </StyledTableRow>
    )
}


export function RegionTable({
    data, // list of objects corresponding to rows
    onAdd, // callback when add button used
    onRemove, // callback when delete button used
    onChange, // callback to change values
    onStore, // callback to push modified changes to server
    tableName, // name to correlate with table
  }) {
  
     return (
      <Container maxWidth={false}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>{tableName}</StyledTableCell>
                <StyledTableCell>Landing Image URL</StyledTableCell>
                <StyledTableCell align="right">Edit</StyledTableCell>
                <StyledTableCell align="right">Delete</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((entry) => (
                <RegionRow
                  regionObject={entry}
                  onChange={onChange}
                  onStore={onStore}
                  onRemove={onRemove}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <Button variant="contained" onClick={onAdd}>Add</Button>
       </Container>
     );
  }