/*******************************************************************************
 * NationalEvents.js - View for National Event Names Page of Admin Interface.
 ******************************************************************************/

import * as React from 'react';
import { api_client } from '../api';
import { fetchToken } from '../auth';
import { BestBetterEventTable } from "./fields/EventTable";

export default function NationalEventsView() {
  const [nationalEvents, setNationalEvents] = React.useState([]);

  React.useEffect(()=>{
    // Load Requisites when page Completes
    getNationalEvents();
  },[]);

  const doUpdateNationalEvent = (event_data) => {
    // Be sure to clone the original object to trigger React's rerendering
    let events = structuredClone(nationalEvents);
    // Iterate Events to Identify the Specific One by its ID
    Array.from(events).forEach((event, idx, _) => {
      if (event.event_id === event_data.event_id) {
        // Update the Specific Event
        events[idx] = event_data;
      }
    })
    setNationalEvents(events);
  }

  const getNationalEvents = () => {
    api_client.get("events/national/", {
      headers: {'Accept': 'application/json'},
    }).then(res => res.data).then(jsonData => {
      // Set the National Event Names
      setNationalEvents(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const addNationalEvent = () => {
    api_client.put("events/national/",
      null,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getNationalEvents()}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const removeNationalEvent = (event_id) => {
    // Get the specific event data
    const payload = nationalEvents.filter(event => event.event_id === event_id)[0];
    api_client.delete("events/national/",
      {
        data: payload,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getNationalEvents()}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const storeNationalEvent = (event_id) => {
    // Get the specific event data
    const payload = nationalEvents.filter(event => event.event_id === event_id)[0];
    api_client.post("events/national/",
      payload,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
      ).then(_ => {getNationalEvents()}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

 return (
  <BestBetterEventTable
    data={nationalEvents}
    onAdd={addNationalEvent}
    onRemove={removeNationalEvent}
    onChange={doUpdateNationalEvent}
    onStore={storeNationalEvent}
  />
 )
}