/*******************************************************************************
 * AdminAccount.js - View for Admin Account Page of Admin Interface.
 ******************************************************************************/

import * as React from 'react';
import { api_client } from '../api';
import { fetchToken } from '../auth';
import { BestBetterSettingsTable } from "./fields/SettingsTable";

export default function AdminAccountView() {
  const [adminUser, setAdminUser] = React.useState({});

  React.useEffect(()=>{
    // Load Requisites when page Completes
    getAdminUserInfo();
  },[]);

  const getAdminUserInfo = () => {
    api_client.get("admin-info", {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${fetchToken()}`
      },
    }).then(res => res.data).then(jsonData => {
      // Set the Lychee Data
      setAdminUser(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const doUpdateAdminUserInfo = (newAdminUserInfo) => {
    api_client.post("admin-info",
      {
        admin_email: newAdminUserInfo.admin_email.value,
        admin_username: newAdminUserInfo.admin_username.value,
        admin_password: newAdminUserInfo.admin_password.value,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getAdminUserInfo();}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

 return (
  <BestBetterSettingsTable
    rows={adminUser}
    onChange={doUpdateAdminUserInfo}
    tableName="Admin Account"
  />
 )
}