/*******************************************************************************
 * login.js
 * 
 * Admin portal login page.
 ******************************************************************************/

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getDesignTokens, getSavedThemeMode } from "../theme";
import { setToken } from './auth';
import axios from "axios";

const client = axios.create({
  baseURL: "/" 
});

function Copyright(props) {
  const [copyrightHolder, setCopyrightHolder] = React.useState("");
  const [redirect, setRedirect] = React.useState("");

  if (copyrightHolder === '' || redirect === '') {
    // Load Data if None Has Been Collected
    client.get("api/v1/about", {
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      setCopyrightHolder(jsonData.copyright);
      setRedirect(jsonData.redirect);
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
  }

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href={redirect}>
        {copyrightHolder}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme(getDesignTokens(getSavedThemeMode()));

export default function LoginPortal() {
  const [loginAlert, setLoginAlert] = React.useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    client.post("login", {
      username: data.get('username'),
      password: data.get('password'),
    }).then((response) => {
      console.log(response);
      if (response.data.message !== null) {
        setLoginAlert(response.data.message);
      } else if (response.data.token !== null) {
        setToken(response.data.token);
        setLoginAlert("");

        // Redirect to Admin
        window.location.href = "/admin";
      }
    })
    .catch((error) => {
      setLoginAlert("Failed to communicate with authentication service.")
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {loginAlert.length > 0 &&
            <Alert severity="error">
              <span style={{ whiteSpace: 'pre-line' }}>{loginAlert}</span>
            </Alert>
          }
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              spellcheck="false"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}