import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import BestBetterAppBar from './AppBar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { getDesignTokens, getSavedThemeMode, setSavedThemeMode } from "../theme";
import useMediaQuery from '@mui/material/useMediaQuery';
import UploadApp from './UploadApp';
import EventLanding from './EventLanding';
import CountyLanding from './CountyLanding';
import LandingOptionGroup from './components/LandingOptions';
import "./App.css";


function BetterPixApp(props) {
  const [pageLoadComplete, setPageLoadComplete] = React.useState(false);
  const [mode, setMode] = React.useState(getSavedThemeMode());
  const [theme, setTheme] = React.useState(createTheme(getDesignTokens(mode)));

  const prefersDarkMode = useMediaQuery(
    '(prefers-color-scheme: dark)',
    { noSsr: true }
  );
  
  React.useEffect(()=>{

    // Theme Default Setter
    const setDefaultTheme = (themePreference) => {
      // Set the default color profile - only if we haven't done so before!
      if (!pageLoadComplete) {
        var defaultTheme = getSavedThemeMode();
        if (defaultTheme === null){
          defaultTheme = (themePreference ? 'dark' : 'light');
          setSavedThemeMode(defaultTheme);
        }
        setMode(defaultTheme);
        document.documentElement.setAttribute('data-color-mode', defaultTheme);
        setTheme(createTheme(getDesignTokens(defaultTheme)));
        setPageLoadComplete(true);
      }
    }

    // Load Requisites when page Completes
    setDefaultTheme(prefersDarkMode);
  },[prefersDarkMode, pageLoadComplete]);

  // Theme Changer Function
  const toggleThemeSetting = () => {
    var newTheme = (mode === 'light' ? 'dark' : 'light');
    setSavedThemeMode(newTheme);
    setMode(newTheme);
    setTheme(createTheme(getDesignTokens(newTheme)));
  }
  

  return(
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <BestBetterAppBar
          mode={mode}
          onThemeChange={toggleThemeSetting}
        />
        {props.children}
      </div>
    </ThemeProvider>
  );
}

const landingEvents = [
  {name: "", slug: "National Events", path: "/national", image_id: ""},
  {name: "", slug: "State Events", path: "/state", image_id: ""},
  {name: "", slug: "District Events", path: "/district", image_id: ""},
  {name: "", slug: "County Events", path: "/county", image_id: ""},
];

export default function AppRouter() {

  return (
    <BetterPixApp>
      <Routes>
        <Route path={"/"} element={<LandingOptionGroup eventsList={landingEvents}/>} />
        <Route path={"/national"} element={<EventLanding selection={"national"} />} />
        <Route path={"/national/:event"} element={<UploadApp />} />
        <Route path={"/state"} element={<EventLanding selection={"state"} />} />
        <Route path={"/state/:event"} element={<UploadApp />} />
        <Route path={"/district"} element={<EventLanding selection={"district"} />} />
        <Route path={"/district/:region"} element={<EventLanding selection={"district"} />} />
        <Route path={"/district/:region/:event"} element={<UploadApp />} />
        <Route path={"/county"} element={<CountyLanding selection={"county"} />} />
        <Route path={"/county/:region"} element={<EventLanding selection={"county"} useGeneric={true} />} />
        <Route path={"/county/:region/:event"} element={<UploadApp />} />
      </Routes>
    </BetterPixApp>
  );
}