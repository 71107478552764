import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import InfoIcon from '@mui/icons-material/Info';

export default function FloatingInfoButton() {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  
  const openRepo = () => {
    const newWindow = window.open(
      "https://gitlab.stanleysolutionsnw.com/idaho4h/4HPhotoUploader",
      '_blank',
      'noopener,noreferrer'
    )
    if (newWindow) newWindow.opener = null
    setDialogOpen(false);
  }

  return (
    <div>
      <div style={{right: 10, bottom: 10, position: "fixed"}}>
        <IconButton
          variant="contained"
          onClick={() => {setDialogOpen(true)}}
          sx={{
            borderRadius: 8,
            backgroundColor: "fff",
            "&:hover": {
              backgroundColor: "fff",
              filter: "brightness(85%)",
            },
          }}
        >
          <InfoIcon/>
        </IconButton>
      </div>
      <React.Fragment>
        <Dialog onClose={() => {setDialogOpen(false)}} open={dialogOpen}>
          <DialogTitle>About BetterPix</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="subtitle2">
                the better picture sharing service for 4-H and youth events
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={openRepo} startIcon={<CodeIcon/>}>
              Open Source Repository
            </Button>
            <Button onClick={() => {setDialogOpen(false)}} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
}
