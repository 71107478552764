/*******************************************************************************
 * StateEvents.js - View for State Event Names Page of Admin Interface.
 ******************************************************************************/

import * as React from 'react';
import { api_client } from '../api';
import { fetchToken } from '../auth';
import { BestBetterEventTable } from "./fields/EventTable";

export default function StateEventsView() {
  const [stateEvents, setStateEvents] = React.useState([]);

  React.useEffect(()=>{
    // Load Requisites when page Completes
    getStateEvents();
  },[]);

  const doUpdateStateEvent = (event_data) => {
    // Be sure to clone the original object to trigger React's rerendering
    let events = structuredClone(stateEvents);
    // Iterate Events to Identify the Specific One by its ID
    Array.from(events).forEach((event, idx, _) => {
      if (event.event_id === event_data.event_id) {
        // Update the Specific Event
        events[idx] = event_data;
      }
    })
    setStateEvents(events);
  }

  const getStateEvents = () => {
    api_client.get("events/state/", {
      headers: {'Accept': 'application/json'},
    }).then(res => res.data).then(jsonData => {
      // Set the State Event Names
      setStateEvents(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const addStateEvent = () => {
    api_client.put("events/state/",
      null,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getStateEvents()}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const removeStateEvent = (event_id) => {
    // Get the specific event data
    const payload = stateEvents.filter(event => event.event_id === event_id)[0];
    api_client.delete("events/state/",
      {
        data: payload,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(_ => {getStateEvents()}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const storeStateEvent = (event_id) => {
    // Get the specific event data
    const payload = stateEvents.filter(event => event.event_id === event_id)[0];
    api_client.post("events/state/",
      payload,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
      ).then(_ => {getStateEvents()}).catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

 return (
  <BestBetterEventTable
    data={stateEvents}
    onAdd={addStateEvent}
    onRemove={removeStateEvent}
    onChange={doUpdateStateEvent}
    onStore={storeStateEvent}
  />
 )
}