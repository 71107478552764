/*******************************************************************************
 * Admin API Support System
 ******************************************************************************/
import { fetchToken, clearToken, setToken } from './auth';
import axios from "axios";

// Use Window Location to Derive Appropriate Protocol
const baseURL = new URL(window.location.origin);
baseURL.pathname = "/api/v1";

export const api_client = axios.create({baseURL: baseURL.href});

export function refreshTokenCall() {
  fetch("/refresh-token", {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${fetchToken()}`
    },
    body: '{}'
  }).then(res => {
    if ( res.status === 401 ){
      // Timed Out? Goodbye! Go back to start.
      clearToken();
      window.location.href = "/login";
    } else {
      res.json().then(jsonData => {
        // Store the Updated Token
        setToken(jsonData.token);
      })
    }
  }).catch((error) => {
    if (error.response.status === 401 ){
        clearToken();
    }
    if( error.response ){
      console.log(error.response.data); // => the response payload
    }
    window.location.href = "/login";
    return Promise.reject(error.message);
  });
}

api_client.interceptors.response.use((response) => {
  refreshTokenCall();
  
  // Return the Regular Response
  return response;
});