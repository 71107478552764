/*******************************************************************************
 * Admin.js
 * 
 * Admin javascript application definition - a thin wrapper around the other
 * components that are used for the 4-H Photo Uploader Admin Portal.
 ******************************************************************************/
import React, { Component } from "react";
import { Grid, Box, Snackbar, Alert } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import BestBetterAdminNavigator from './AdminNavigation';
import CssBaseline from '@mui/material/CssBaseline';
import { getDesignTokens, getSavedThemeMode, setSavedThemeMode } from "../theme";
import { refreshTokenCall } from './api';
import "./Admin.css";
import GeneralSettingsView from "./views/GeneralSettings";
import DistrictNamesView from "./views/DistrictNames";
import NationalEventsView from "./views/NationalEvents";
import StateEventsView from "./views/StateEvents";
import DistrictEventsView from "./views/DistrictEvents";
import AdminAccountView from "./views/AdminAccount";
import EmailSettingsView from "./views/EmailSettings";
import ServerLogsView from './views/ServerLogs';
import PhotoStorageView from "./views/PhotoStorage";
import CountyEventsView from "./views/CountyEvents";

const pageView=()=>{
  var view = new URLSearchParams(window.location.search).get("view");
  if (!view) {
    view = "";
  }
  return view;
}

class Admin extends Component {
  constructor(props) {
    super(props);


    // Set Default State Options
    this.state = {
      error: null,
      pageLoadComplete: false,
      mode: getSavedThemeMode(),
      theme: createTheme(getDesignTokens(getSavedThemeMode())),
      activePage: pageView(),
      appTitle: '',
      albumsPage: '',
      snackbarOpen: false,
      snackbarMsg: "",
      severity: "success",
    };

    // Bind Class Method
    this.toggleThemeSetting = this.toggleThemeSetting.bind(this);
    this.setDefaultTheme = this.setDefaultTheme.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePopup = this.handlePopup.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }


  // Run any code that needs to be executed on page load.
  componentDidMount() {
    // Verify Login
    refreshTokenCall();
    this.setState({pageLoadComplete: true});
    fetch("/api/v1/about", {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      this.setState({
        appTitle: jsonData.title,
        albumsPage: jsonData.albums_redirect,
      })
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
  }

  handlePageChange = () => {
    this.setState({
      activePage: pageView(),
    });
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      snackbarOpen: false,
      snackbarMsg: "",
      uploading: false,
    });
  };

  handlePopup = (data) => {
    // Show Popup with Defined Message and Severity
    console.log(data.message);

    // Update Snackbar
    this.setState({
      snackbarMsg: data.message,
      snackbarOpen: true,
      severity: data.severity,
    })
  };

  // Theme Changer Function
  toggleThemeSetting() {
    var newTheme = (this.state.mode === 'light' ? 'dark' : 'light');
    setSavedThemeMode(newTheme);
    this.setState({mode: newTheme});
    this.setState({theme: createTheme(getDesignTokens(newTheme))});
  }

  // Theme Default Setter
  setDefaultTheme(themePreference) {
    // Set the default color profile - only if we haven't done so before!
    if (!this.state.pageLoadComplete) {
      var defaultTheme = getSavedThemeMode();
      if (defaultTheme === null){
        defaultTheme = (themePreference ? 'dark' : 'light');
        setSavedThemeMode(defaultTheme);
      }
      this.setState({mode: defaultTheme});
      this.setState({theme: createTheme(getDesignTokens(defaultTheme))});
    }
  }

  render() {

    return (
      <ThemeProvider theme={this.state.theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex' }}>
          <BestBetterAdminNavigator
            mode={this.state.mode}
            pageTitle={this.state.activePage}
            onLoad={this.setDefaultTheme}
            onThemeChange={this.toggleThemeSetting}
            onPageChange={this.handlePageChange}
            albumsRedirect={this.state.albumsPage}
                      />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              style={{ minHeight: '90vh' }}
            >
              <Grid item m={2} pt={3}>
              <Box m={2} sx={{ flexGrow: 1 }}>
                  {this.state.activePage === "" && <GeneralSettingsView onPopup={this.handlePopup} />}
                  {this.state.activePage === "General" && <GeneralSettingsView />}
                  {this.state.activePage === "District Names" && <DistrictNamesView />}
                  {this.state.activePage === "National Events" && <NationalEventsView />}
                  {this.state.activePage === "State Events" && <StateEventsView />}
                  {this.state.activePage === "District Events" && <DistrictEventsView />}
                  {this.state.activePage === "County Events" && <CountyEventsView />}
                  {this.state.activePage === "Photo Storage" && <PhotoStorageView />}
                  {this.state.activePage === "Email Settings" && <EmailSettingsView />}
                  {this.state.activePage === "Admin Account" && <AdminAccountView />}
                  {this.state.activePage === "Server Logs" && <ServerLogsView />}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Snackbar
          open={this.state.snackbarOpen}
          autoHideDuration={6000}
          onClose={this.handleClose}
        >
          <Alert
            onClose={this.handleClose}
            severity={this.state.severity}
            sx={{ width: '100%' }}
          >
            {this.state.snackbarMsg}
          </Alert>
        </Snackbar>
      </ThemeProvider>
    );
  }
}

export default Admin;
