/*******************************************************************************
 * Landing.js
 * 
 * Main landing javascript application definition - a thin wrapper around the
 * other components that are used for the 4-H Photo Uploader.
 ******************************************************************************/
import React from "react";
import {
  Box,
  Chip,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";

export default function CountyLanding(props) {
  const [selectOptions, setSelectOptions] = React.useState({});

  // Run any code that needs to be executed on page load.
  React.useEffect(()=>{

    // Load the Events from Which Selection Can be Made
    fetch(`/api/v1/selection/${props.selection}`, {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      // Set the Page Information
      setSelectOptions(jsonData);
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
    
  },[props.selection]);


  return (
    <Grid
      container
      spacing={0.5}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '90vh' }}
    >
      {(Object.keys(selectOptions).length === 0) && <>
        <br />
        <Typography >
          There are no configured events for this category.
          If this is not expected, contact your site administrator.
        </Typography>
      </>}
      {(Object.keys(selectOptions).length !== 0) && <>
        <Grid item xs={6}>
          {Object.entries(selectOptions).map(([key, _]) => (
            <Chip label={key} component="a" href={`#${key}`} clickable />
          ))}
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ minWidth: 360, maxWidth: 1400 }}>
            <List
              sx={{
                width: '100%',
                maxWidth: 360,
                bgcolor: 'background.paper',
                position: 'relative',
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                height: '85vh',
                '& ul': { padding: 0 },
              }}
              subheader={<li />}
            >
              {Object.entries(selectOptions).map(
                ([key, list_values]) => (
                  <li key={`section-${key}`}>
                    <ul>
                      <ListSubheader
                        id={key}
                        sx={{
                          bgcolor: 'primary.main',
                          color: 'primary.text',
                        }}
                      >
                        {key}
                      </ListSubheader>
                      {list_values.map((item) => (
                        <ListItemButton
                          key={`item-${key}-${item}`}
                          component="a"
                          href={`${props.selection}/${item}`}
                          sx={{
                            bgcolor: 'secondary.alt',
                            color: 'secondary.text',
                          }}
                        >
                          <ListItemText primary={item}/>
                        </ListItemButton>
                      ))}
                    </ul>
                  </li>
                )
              )}
            </List>
          </Box>
        </Grid>
      </>}
    </Grid>
  );
}
