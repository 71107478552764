/*******************************************************************************
 * PhotoStorage.js - View for Photo Storage Page of Admin Interface.
 ******************************************************************************/

import * as React from 'react';
import { Alert, Box, Chip, Snackbar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { api_client } from '../api';
import { fetchToken } from '../auth';
import { BestBetterSettingsTable } from "./fields/SettingsTable";

export default function PhotoStorageView() {
  const [alertBarOpen, setAlertBarOpen] = React.useState(false);
  const [lycheeInfo, setLycheeInfo] = React.useState({});
  const [connectionStatus, setConnectionStatus] = React.useState({});
  const [attemptReconnectStatus, setAttemptReconnectStatus] = React.useState(false);
  const [attemptReconnectResult, setAttemptReconnectResult] = React.useState({});

  React.useEffect(()=>{
    // Load Requisites when page Completes
    getLycheeConfig();
    getLycheeConnectionStatus();
  },[]);

  const getLycheeConfig = () => {
    api_client.get("storage/lychee/list-settings", {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${fetchToken()}`
      },
    }).then(res => res.data).then(jsonData => {
      // Set the Lychee Data
      setLycheeInfo(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const doUpdateLycheeInfo = (newLycheeData) => {
    setLycheeInfo(newLycheeData);
    api_client.post("storage/lychee/set-settings",
      {
        lychee_url: newLycheeData.lychee_url.value,
        lychee_api_user: newLycheeData.lychee_api_user.value,
        lychee_api_token: newLycheeData.lychee_api_token.value,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(res => res.data).then(jsonData => {
      // NOTE: This is a very important "work-around" to get state to refresh
      //       correctly following a change.
      setLycheeInfo(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const getLycheeConnectionStatus = () => {
    api_client.get("storage/lychee/connection-status", {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${fetchToken()}`
      },
    }).then(res => res.data).then(jsonData => {
      // Set the Lychee Data
      setConnectionStatus(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const attemptReconnect = () => {
    setAttemptReconnectStatus(true);
    api_client.post("storage/lychee/trigger-reconnect",
      {},
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(res => res.data).then(jsonData => {
      if (jsonData.msg !== "Successfully Connected.") {
        setAlertBarOpen(true);
      }
      setAttemptReconnectResult(jsonData);
      setAttemptReconnectStatus(false);
      getLycheeConnectionStatus();
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

 return (
  <>
    <Box
      sx={{
        '& > :not(style)': { m: 1, width: '100hw', minWidth: '800px' },
      }}
    >
      <Chip
        label={"Lychee Server Connection: " + connectionStatus.status}
        color={connectionStatus.connection_good ? "success" : "error"}
      />
    </Box>
    <Box
      sx={{
        '& > :not(style)': { m: 1, width: '100hw', minWidth: '800px' },
      }}
    >
      <BestBetterSettingsTable
        rows={lycheeInfo}
        onChange={doUpdateLycheeInfo}
        tableName="Lychee Configuration"
      />
    </Box>
    <Box
      sx={{
        '& > :not(style)': { m: 1, width: '100hw', minWidth: '800px' },
      }}
    >
      <LoadingButton
        onClick={attemptReconnect}
        endIcon={<CloudSyncIcon />}
        loading={attemptReconnectStatus}
        loadingPosition="end"
        variant="contained"
      >
        Attempt Reconnecting Photo Storage
      </LoadingButton>
    </Box>
    <Snackbar
      open={alertBarOpen}
      autoHideDuration={6000}
      onClose={() => {setAlertBarOpen(false)}}
    >
      <Alert
        onClose={() => {setAlertBarOpen(false)}}
        severity="error"
        sx={{ width: '100%' }}
      >
        {attemptReconnectResult.msg}
      </Alert>
    </Snackbar>
  </>
 )
}