/*******************************************************************************
 * EmailSettings.js - View for Email Settings Page of Admin Interface.
 ******************************************************************************/

import * as React from 'react';
import Button from '@mui/material/Button';
import { api_client } from '../api';
import { fetchToken } from '../auth';
import { BestBetterSettingsTable } from "./fields/SettingsTable";

export default function EmailSettingsView() {
  const [emailSettings, setEmailSettings] = React.useState({});

  React.useEffect(()=>{
    // Load Requisites when page Completes
    getEmailSettings();
  },[]);

  const sendEmail = () => {
    api_client.get("email/test-send-email", {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${fetchToken()}`
      },
    }).then(res => res.data).then().catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const getEmailSettings = () => {
    api_client.get("email/list-settings", {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${fetchToken()}`
      },
    }).then(res => res.data).then(jsonData => {
      // Set the Lychee Data
      setEmailSettings(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const doUpdateEmailSettings = (newEmailSettings) => {
    api_client.post("email/set-settings",
      {
        smtp_server: newEmailSettings.smtp_server.value,
        smtp_port: newEmailSettings.smtp_port.value,
        smtp_from_address: newEmailSettings.smtp_from_address.value,
        smtp_use_tls: newEmailSettings.smtp_use_tls.value,
        smtp_username: newEmailSettings.smtp_username.value,
        smtp_password: newEmailSettings.smtp_password.value,
      },
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}`
        }
      }
    ).then(res => res.data).then(jsonData => {
      // NOTE: This is a very important "work-around" to get state to refresh
      //       correctly following a change.
      setEmailSettings(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

 return (
  <>
    <BestBetterSettingsTable
      rows={emailSettings}
      onChange={doUpdateEmailSettings}
      tableName="Email Settings"
    />
    <br />
    <Button variant="contained" onClick={sendEmail}>Send Test Email</Button>
  </>
 )
}