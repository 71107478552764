import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConsentDialogForm() {
  const [consentFormOpen, setConsentFormOpen] = React.useState(true);
  const [consentText, setConsentText] = React.useState("");

  const handleAccept = () => {
    // Close Popup Form
    setConsentFormOpen(false);
    localStorage.setItem('bestbetterappagreetophotorelease', "yes");
  };

  const handleDisagree = () => {
    // Redirect to "Safe" 4-H Site
    fetch("/api/v1/about", {
      method: "GET",
      headers: {'Accept': 'application/json'},
    }).then(res => res.json()).then(jsonData => {
      window.location.href = jsonData.redirect;
    })
    .catch((error) => {
      if( error.response ){
          console.log(error.response.data); // => the response payload
      }
    });
  }
  
  React.useEffect(()=>{
    // Load the Text
    fetch('/static/consent.txt')
      .then((r) => r.text())
      .then(text  => {
        console.log(text);
        setConsentText(text);
    })
    var storage = localStorage.getItem('bestbetterappagreetophotorelease');
    if (storage === "yes") {
      setConsentFormOpen(false);
    }
  },[]);

  return (
    <div>
      <Dialog
        open={consentFormOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Consent to University of Idaho 4-H Photo Release?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {consentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="secondary">Disagree</Button>
          <Button onClick={handleAccept} color="secondary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
