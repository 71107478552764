/*******************************************************************************
 * GeneralSettings.js - View for General Configuration Page of Admin Interface.
 ******************************************************************************/

import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import { api_client } from '../api';
import { fetchToken } from '../auth';
import { Button } from '@mui/material';

export default function GeneralSettingsView({onPopup}) {
  const [title, setTitle] = React.useState("");
  const [copyright, setCopyright] = React.useState("");
  const [redirect, setRedirect] = React.useState("");
  const [stateId, setStateId] = React.useState("");
  const [consentText, setConsentText] = React.useState("");

  React.useEffect(()=>{
    // Load Requisites when page Completes
    getGeneralSettings();
    getConsentText();
  },[]);

  const getGeneralSettings = () => {
    api_client.get("about", {
      headers: {
        'Accept': 'application/json',
        //'Authorization': `Bearer ${fetchToken()}` // No Auth Required
      },
    }).then(res => res.data).then(jsonData => {
      // Set the General Configuration Data
      setTitle(jsonData.title);
      setCopyright(jsonData.copyright);
      setRedirect(jsonData.redirect);
      setStateId(jsonData.state_id);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }

  const getConsentText = () => {
    api_client.get("consent-text", {
      headers: {
        'Accept': 'application/json',
        //'Authorization': `Bearer ${fetchToken()}` // No Auth Required
      },
    }).then(res => res.data).then(jsonData => {
      // Set the Consent Text Data
      setConsentText(jsonData);
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
      }
    });
  }
  
 const handleChange = (element, value) => {
  if (element === "title") {
    setTitle(value);
  } else if (element === "copyright") {
    setCopyright(value);
  } else if (element === "redirect") {
    setRedirect(value);
  } else if (element === "consent-text") {
    setConsentText(value);
  } else if (element === "state-id") {
    setStateId(value);
  }
 }

 const handlePopup = (data) => {
  onPopup(data);
 }

 const onSave = () => {
  api_client.post("about",
    {
      title: title,
      copyright: copyright,
      redirect: redirect,
      state_id: stateId,
      albums_redirect: "", // not used as of 20230828
    },
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${fetchToken()}` // No Auth Required
      },
    }
  ).then(_ => {
    api_client.post("consent-text",
      {text: consentText},
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${fetchToken()}` // No Auth Required
        },
      }
    ).then(_ => {
      console.log("Stored Configuration.");
      handlePopup({
        message: "Successfully Applied General Settings.",
        severity: "success",
      });
    })
    .catch((error) => {
      if( error.response ){
        console.log(error.response.data); // => the response payload
        handlePopup({
          message: JSON.stringify(error.response.data),
          severity: "error",
        });
      }
    });
  })
  .catch((error) => {
    if( error.response ){
      console.log(error.response.data); // => the response payload
      handlePopup({
        message: error.response.data,
        severity: "error",
      });
    }
  });
 }

 return (
    <div>
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100hw', minWidth: '800px' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="app-title"
        label="Application Title"
        variant="outlined"
        value={title}
        onChange={(event) => {handleChange("title", event.target.value)}}
      />
    </Box>
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100hw', minWidth: '800px' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="copyright"
        label="Copyright"
        variant="outlined"
        value={copyright}
        onChange={(event) => {handleChange("copyright", event.target.value)}}
      />
    </Box>
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100hw', minWidth: '800px' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="state-id"
        label="State ID"
        variant="outlined"
        value={stateId}
        onChange={(event) => {handleChange("state-id", event.target.value)}}
      />
    </Box>
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100hw', minWidth: '800px' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="redirect"
        label="Consent-Decline Redirect"
        variant="outlined"
        value={redirect}
        onChange={(event) => {handleChange("redirect", event.target.value)}}
      />
    </Box>
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '100hw', minWidth: '800px' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="consent-text"
        label="Consent Text"
        variant="outlined"
        multiline
        rows={20}
        maxRows={20}
        value={consentText}
        onChange={(event) => {handleChange("consent-text", event.target.value)}}
      />
    </Box>
    <Button
      onClick={onSave}
      variant="contained"
      color="primary"
      startIcon={<SaveIcon />}
    >
      Save
    </Button>
    </div>
 )
}