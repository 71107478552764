/*******************************************************************************
 * LandingOptions.js
 * 
 * Main javascript application definition - a thin wrapper around the other
 * components that are used for the 4-H Photo Uploader.
 ******************************************************************************/
import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import EventCard from './EventCard';

export default function LandingOptionGroup({
  eventsList, // list of selectable dictionaries
  dataLoadComplete = true, // indication that request to load has finished
}) {

  const [gridSxCount, setGridSxCount] = React.useState(3);

  
  React.useEffect(()=>{

    if (eventsList.length % 4 === 0) {
      // Set for 4 Columns
      setGridSxCount(3); /* (12 / 4) */
    } else if (eventsList.length % 3 === 0) {
      // Set for 3 Columns
      setGridSxCount(4); /* (12 / 3) */
    } else if (eventsList.length === 1) {
      // Set Full Width
      setGridSxCount(12);
    } else if (eventsList.length === 2) {
      // Set Half-Width
      setGridSxCount(6);
    } else {
      // Default to the Rule of 3
      setGridSxCount(4); /* (12 / 3) */
    }
    
  },[eventsList]);
  
  return (
    <Box m={2} sx={{ flexGrow: 1 }}>
      {(eventsList.length === 0 && dataLoadComplete) &&
        <Typography >
          There are no configured events for this category.
          If this is not expected, contact your site administrator.
        </Typography>
      }
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2 }}
      >
        {eventsList.map((event, _) => (
          <Grid item sm={gridSxCount} xs={12}>
            <EventCard
              eventName={event.name}
              eventBrief={event.slug}
              pathLink={('path' in event)? event.path : (
                (!!event.slug) ? event.slug : event.name
              )}
              eventImageId={event.image_id}
            />
          </Grid>
          ))}
      </Grid>
    </Box>
  );
}
  