/*******************************************************************************
 * SettingRow - A generic table row, allowing editing or deletion of data.
 ******************************************************************************/

 import * as React from 'react';
 import Typography from '@mui/material/Typography';
 import TextField from '@mui/material/TextField';
 import IconButton from '@mui/material/IconButton';
 import OutlinedInput from '@mui/material/OutlinedInput';
 import InputAdornment from '@mui/material/InputAdornment';
 import Switch from '@mui/material/Switch';
 import Visibility from '@mui/icons-material/Visibility';
 import VisibilityOff from '@mui/icons-material/VisibilityOff';

function HidablePassword({password, onChange}) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <OutlinedInput
      id="outlined-password"
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={onChange}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
    />
  )
}


export function EditableTextField({
    value, // text of the editable input
    inEditMode, // state indicator to show value as "saved" or "editable"
    secret=false, // control to hide text when not in edit mode
    setValue, // callback used to update the external value
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      {secret &&
        // This text is "secret" and should be hidden when not in edit
        <>
          {inEditMode &&
            <HidablePassword password={value} onChange={handleChange}/>
          }
          {!inEditMode &&
            <Typography>
              &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
            </Typography>
          }
        </>
      }
      {!secret &&
        // This is normal text and should be displayed if not in edit
        <>
          {inEditMode && <TextField value={value} onChange={handleChange}/>}
          {!inEditMode && <Typography>{value}</Typography>}
        </>
      }
    </div>
  )
}


export function EditableOverflowTextField({
    value, // text of the editable input
    inEditMode, // state indicator to show value as "saved" or "editable"
    setValue, // callback used to update the external value
}) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      {inEditMode && <TextField value={value} onChange={handleChange}/>}
      {!inEditMode && 
        <div style={{overflow: "hidden", textOverflow: "ellipsis", width: '11rem'}}>
          <Typography noWrap>{value}</Typography>
        </div>
      }
    </>
  )
}


export function EditableBooleanField({
    value, // text of the editable input
    inEditMode, // state indicator to show value as "saved" or "editable"
    setValue, // callback used to update the external value
}) {
  const handleChange = (event) => {
    setValue(!value);
  };

  return (
    <div>
        {inEditMode && <Switch checked={value} onChange={handleChange}/>}
        {!inEditMode && <Switch checked={value} disabled/>}
    </div>
  )
}