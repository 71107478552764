/*******************************************************************************
 * Custom Photo Uploader Tooling.
 ******************************************************************************/

import axios from 'axios';

const delay = ms => new Promise(res => setTimeout(res, ms));

function makeUploadRequest(
    files, // discrete (single) file for upload
    endpoint, // location to submit upload
    onPopup, // callback to accept a map including message and severity
    onSuccess, // callback when upload was completed successfully
) {
    // Create an object of formData
    const formData = new FormData();

    let file = files[0];

    // Append a single file to the dataset
    formData.append("photos", file);
    console.log(JSON.stringify({"Name": file.name, "Size": file.size}));

    // Request made to the backend api
    // Send formData object
    axios(
        {
            method: 'post',
            url: endpoint,
            data: formData,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            },
        }
    ).then((response) => {
        if (response.status === 202) {
            // Watch for Backend-Reported Error
            if (!response.data.error){
                onSuccess(file.name);
            } else {
                onPopup({
                    message: response.data.detail,
                    severity: "error",
                });
            }
        } else {
            console.error(response);
            onPopup({
                message: response.data.detail,
                severity: "error",
            });
        }
        // Handle Uploading the Next File
        if (files.length > 1) {
            // Upload Next File
            makeUploadRequest(
                files.slice(1, files.length),
                endpoint,
                onPopup,
                onSuccess
            );
        } else {
            // Wait a brief moment, then report the completion status
            delay(100).then(() => {onSuccess(null);})
        }
        
    }).catch((error) => {
        if( error.response ){
            console.error(error.response.data); // => the response payload
            try {
                if ("detail" in error.response.data) {
                    onPopup({
                        message: error.response.data.detail,
                        severity: "error",
                    });
                }
            } catch {;}
            onPopup({
                message: (
                    "The server is presently experiencing an error, and"
                    +" is unable to upload photos."
                ),
                severity: "error",
            });
            // Mark Completion
            onSuccess(null);
        }
    });
}

export default function uploadEventPhotos({
    files, // list of files to be uploaded
    regionEventSelection, // specific region for which to upload
    uploadOptions={}, // additional specifier options for the upload
    onPopup, // callback to accept a map including message and severity
    onSuccess, // callback when upload was completed successfully
}) {

    // Sanitize Selection
    if (regionEventSelection.startsWith("/")) {
        regionEventSelection = regionEventSelection.slice(1);
    }
    // Make PUT Request with Payload
    var endpoint = new URL(
        `/api/v1/upload/${regionEventSelection}`,
        window.location.href
    );
    for (const [key, value] of Object.entries(uploadOptions)) {
        if (!!value) {
          // Only Use Value if it's Not Null
          endpoint.searchParams.append(key, value);
        }
    }

    // Make Upload Request
    makeUploadRequest(files, endpoint, onPopup, onSuccess);
}